/****************************************************************************************************

    (c) 2015 EXPERTDESIGN

    skype:  expertdesign.hu, voip: 06-21-380-5870
    e-mail: info@expertdesign.hu, http://www.expertdesign.hu

*****************************************************************************************************/
var COOKIE_NAME_PREFIX = "VMT";

$(document).ready(function()
{
    //enterre_tovabb();
    enterre_nincs_submit();
    modal_init();
    dropdown_init();
    topmenu_hide();    
});
//-----------------------------------------------------------------------------------------------------------------------
function topmenu_hide()
{
    var didScroll;
    
    // on scroll, let the interval function know the user has scrolled
    $(window).scroll(function(event){
        didScroll = true;
    });
    
    // run hasScrolled() and reset didScroll status
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);
    
    function hasScrolled() {
         var st = $(this).scrollTop();
         if (st > 80) {
            $('.navbar-inverse').addClass('nav-up');
         }
         else {
            $('.navbar-inverse').removeClass('nav-up');
         }
    }
}
//-----------------------------------------------------------------------------------------------------------------------
function dropdown_init()
{
    $('.dropdown-menu').click(function(e) {
        if($(this).hasClass('no-close')) {
            e.stopPropagation();
        }
    });
}
//-----------------------------------------------------------------------------------------------------------------------
function enterre_tovabb()
{
    $('form input, form select').bind('keypress', function(e)
    {
        if(e.which==13)
        {
            e.preventDefault();
            var idx = $('form input, form select, form button').index(this);
            $('form input, form select, form button').eq(idx+1).focus();
        }
    });
}
//-----------------------------------------------------------------------------------------------------------------------
function enterre_nincs_submit()
{
    $('form input').bind('keypress', function(e)
    {
        if(e.which==13)
        {
            e.preventDefault();
            return false;
        }
    });
}
//-----------------------------------------------------------------------------------------------------------------------
function modal_init()
{
    $(".modal").on('shown.bs.modal', function() {
        $('form input:text[value=""]:visible:first').not('.hasDatepicker').focus();
        $(this).draggable({
            handle: ".modal-header"
        });
        $('.modal-header', this).css("cursor","move");
    })
    .on('hidden.bs.modal', function() {
        $('#js_submit').val('');
        $('#command').val('');
    });
}
//-----------------------------------------------------------------------------------------------------------------------
function js_modal(modal)
{
    $('#js_modal').val(modal).click();
}
//-----------------------------------------------------------------------------------------------------------------------
function js_command(command)
{
    $('#js_command').val(command).click();
}
//-----------------------------------------------------------------------------------------------------------------------
function popup_close(func, popup_selector, namespace)
{
	var ns = (namespace === undefined) ? 'popup' : namespace;

	$('body').on('click.'+ns, function (e) {	
		var popup = $(popup_selector);
		if (!popup.is(e.target) && popup.has(e.target).length === 0) {
			func.apply();
			$('body').off('click.'+ns);
		}
	});

	$('body').on('keyup.'+ns, function (e) {	
		if (e.keyCode == 27) {
			func.apply();
			$('body').off('keyup.'+ns);
		}
	});
}
//-----------------------------------------------------------------------------------------------------------------------
function last_tab(container)
{
    $('#' + container + ' a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        create_cookie(COOKIE_NAME_PREFIX + '_' + container + '_lasttab', $(e.target).attr('href'), 90);
    });

    var lasttab = read_cookie(COOKIE_NAME_PREFIX + '_' + container + '_lasttab');

    if (lasttab) {
        $('#' + container + ' a[href="' + lasttab + '"]').tab('show');
    }
    else {
        $('#' + container + ' a:first').tab('show');
    }
}
//-----------------------------------------------------------------------------------------------------------------------
function create_cookie(name,value,days)
{
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
}
//----------------------------------------------------------------------------------------------------------------------
function read_cookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
//-----------------------------------------------------------------------------------------------------------------------------------------
(function($) {

    $.fn.penz = function(opt)
    {
        var defaults = {
            kerekites: false,
            tizedes: 2,
            penznem: ''
        };

        var options = $.extend(defaults, opt);

        return this.each(function()
        {
            $(this).bind('keyup focus', function()
            {
                var str = $(this).val();
                str = str.replace(",", ".");
                str = str.replace(/[^0-9\.]/g,'');
                $(this).val(str);
            })
            .bind('blur', function()
            {
                var n = parseFloat($(this).val());

                n = (options.kerekites==true) ?  Math.round(n) : n;

                if (isNaN(n) == false)
                {
                    var str = n.toFixed(options.tizedes).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");
                    str = (options.penznem) ? str + ' ' + options.penznem : str;

                    $(this).val(str);
                }
                else
                {
                    $(this).val('');
                }

            });

            $(this).trigger('blur');
            $(this).css('text-align', 'right');
        });
    };

})(jQuery);
//-----------------------------------------------------------------------------------------------------------------------------------------
;(function($, window, document, undefined) {

    var pluginName = "datagrid",
        div_tarto,
        cvar,
        rows,
        primary,
        toolbar,
        that,
        defaults = {
            scrolly : 60,
            toolbarName : "szuro_toolbar",
            hideToolbar : true,
            floatingHead: true,
            buttons : [],
            footerSize : 44,
            autoResize: true,
            contextMenu: false
        };


    function Plugin(elem, options)
    {
        that = this;
        div_tarto = $(elem);
        this.options   = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name     = pluginName;
        this.init();
    }


    Plugin.prototype = {

        init: function()
        {


            rows    = div_tarto.find('table tbody > tr').not('.subtable');  // összes <tr> kivéve ami a subtable-t tartalmazza
            primary = rows.eq(0).data('name');                              // datagrid fő táblájának a primary index neve (minden sorhoz hozzá van rendelve)
            cvar    = COOKIE_NAME_PREFIX + '_' + primary + '_grid';         // süti neve (mentéshez)
            toolbar = $("#"+this.options.toolbarName);                      // görgetés megindításakor elrejtendő DOM elem pl. szűrő toolbar

            div_tarto.find('tbody').bind('click', this.selectRow)           // a kattintásra szelekció
                .bind('dblclick' , function() {                             // duppla katintás nyitás/zárás
                    $(this).children('td.toggle').find('img').trigger('click');
                });

            // thead checkbox esemény
            this.checkSetup();

            // kiválasztott sor beállítás submit után
            this.setSelected();

            // sorok nyitása a sütibe mentett adatokból
            this.readCookie();

            // [+]/[-] jel kattintás beállítás (nyitás/zárás)
            div_tarto.find('tbody td.toggle img').click(this.toggleRow);

            // jobb klikk-re saját kontext menü
            if (this.options.contextMenu) {
                document.oncontextmenu = function() {return false;};
                //div_tarto.find('td').mousedown(this.rightClick);
                $('tr:not(".subtable") td', div_tarto).mousedown(this.rightClick);
            }

            // scrollozás eseménykor: a toolbar elrejtés
            if (this.options.hideToolbar===true && toolbar.length) {
                div_tarto.scroll(this.scroll); // scroll és floathead
            }

            // kiragasztott tábla head
            if (this.options.floatingHead===true) {
                this.floatHead();
            }

            // tartó méretezése a képernyő magasságához
            if (this.options.autoResize===true)
            {
                $(window).resize(function() {
                    div_tarto.height($(window).height() - div_tarto.offset().top - that.options.footerSize);
                }).resize();
            }

            // függőleges scroll pozició mentése
            $('form').bind('submit', function() {
                $('#posy').val(div_tarto.scrollTop());
            });

            // függőleges scroll pozició visszaállítása
            var scy = $('#posy').val();
            div_tarto.scrollTop(scy);

            if (this.options.buttons) this.setButtonCounter();
        },


        setSelected: function()
        {
            var value = $('#'+primary).val();
            if (value) {
                $('tr[data-name='+primary+'][data-value='+$('#'+primary).val()+']').addClass('active');
            }
        },


        selectRow: function(event)
        {
            var row = $(event.target).parents('tr').not('.subtable'),
                szulo_row = row.parents('tr').prev('tr'),
                name, value;

            // az összes sorról leveszem
            $(this.rows).removeClass('active');
            row.addClass('active');

            // beállítom az rejtett input mező értékét
            name  = row.data('name');
            value = row.data('value');
            $('#'+name).val(value);

            // subtable-ba kattintáskor a szülő tábla rejtet id-jét is beállítom
            //$('#'+szulo_row.data('name')).val(szulo_row.data('value'));

            // eseményt küldök a kattintásról
            div_tarto.trigger('gridselect', [name,value]);
        },


        readCookie: function()
        {
            var cookie = read_cookie(cvar);

            if (cookie)
            {
                $.each(cookie.split(','), function(index,value) {
                    var t = $('tr[data-name='+primary+'][data-value='+value+']').next('tr.subtable');
                    t.show();
                    t.prev('tr').find('td.toggle img').attr('src','/public/images/minusz.gif');
                });
            }
        },


        toggleRow: function(e)
        {
            var img         = $(e.target); // a [+]/[-] képgomb
            var toggle_tr   = img.parents('td.toggle').parents('tr'); // a kattintás sora
            var subtable_tr = toggle_tr.next('tr'); // az img gombhoz legközelebb eső táblázat sor (amiben a subtable van)

            // eltüntetem vagy láthatóvá teszem
            subtable_tr.toggle();

            // [+]/[-] ikon változtatása
            img.attr('src', (img.attr('src') == '/public/images/plusz.gif') ? '/public/images/minusz.gif' : '/public/images/plusz.gif');

            // cookie-ba mentem a sor állapotát, a hivatkozási id-jével
            that.saveCookie(toggle_tr.data('value'), subtable_tr.is(':visible'));
        },


        rightClick : function(e)
        {
            if (e.button == 2)
            {
                // hanyadik mélység a kattintott tábla (subtable)
                var depth = $(e.target).parents('table').length;
                var menu  = $('#'+that.options.contextMenu[depth-1]).html();

                that.selectRow(e);
                div_tarto.find('tbody .dropdown').remove();

                if (menu)
                {
                    $(e.target).append('<div class="dropdown"><a href="#" data-toggle="dropdown"></a><ul class="dropdown-menu">' + menu + '</ul></div>');
                    $('td .dropdown').css({'top':e.offsetY,'left':e.offsetX}).toggleClass('open');
                }
            }
        },


        saveCookie: function(id, visible)
        {
            var tomb = [];
            var cookie_arr = [];
            var cookie_str = read_cookie(cvar);

            // a cookie-ból érkező, vesszővel elválasztott string-et tömbbé alakítom
            if (cookie_str) cookie_arr = cookie_str.split(',');

            // átteszem egy új asszociatív tömbbe, ahol a tömbindex és az érték is a sor azonosító id-je lesz.
            for (var i in cookie_arr) {
                tomb[ cookie_arr[i] ] = cookie_arr[i];
            }

            // ha nem látható a subtable, vagyis "csukva van a sor", akkor kitörlöm az ilyen indexű elemet
            if (!visible) {
                delete tomb[ id ];
            }
            else {
                tomb[ id ] = id; // egyébként meg létrehozok egy ilyen tömbindex-ű és értékű tömbelemet
            }

            // a javascript úgy kezel asszociatív tömböket, hogy pl. tomb[1] = "valami"; tomb[20] = 'bármi', között létrejön 19 üres tömbelem
            // ezért a filterrel az üres köztes elemeket kitörlöm
            tomb = tomb.filter(function(){return true;});

            // visszaalakítom tömböt cookie-ba írható vesszővel elválasztott szöveggé, csak azokat az id-ket fogja tartalmazni, amelyikhez "nyitott sorok" tartoznak.
            cookie_str = tomb.join(',');
            create_cookie(cvar, cookie_str, 14); // 14 napig él a cookie
        },


        checkSetup: function()
        {
            div_tarto.find('thead :checkbox').click(function(e) {
                rows.find('.check').children(':checkbox').attr('checked', $(e.target).is(':checked'));
                that.setButtonCounter();
            });

            div_tarto.find('tbody :checkbox').click(function(e) {
                that.setButtonCounter();
            });
        },


        setButtonCounter: function()
        {
            var count = rows.find(':checkbox').filter(':checked').length;

            $.each(that.options.buttons, function(index, value) {
                    var button = $('#'+value);
                    if (button) {
                        var parts = button.html().match(/[^\(\d\)]*/i);
                        button.html(parts[0] + (count ? " ("+count+")" : ""));
                    }
            });
        },


        scroll: function()
        {
            var st = div_tarto.scrollTop();

            if (st > that.options.scrolly && toolbar.is(":visible")===true)
            {
                that.toggleToolbar("hide");
            }
            else if (st < that.options.scrolly && toolbar.is(":visible")===false)
            {
                that.toggleToolbar("show");
            }
        },


        toggleToolbar: function(cmd)
        {
            if (cmd == "show")
            {
                toolbar.show();
            }
            else if (cmd == "hide")
            {
                toolbar.hide();
            }

            $(window).resize();

            if (that.options.floatingHead) {
                div_tarto.find('table.datagrid.main').floatThead('reflow');
            }
        },


        floatHead: function()
        {
            //var offset = div_tarto.offset();
            div_tarto.find('table.datagrid.main').floatThead(
            {
                //scrollingTop: offset.top,
                scrollContainer: function(table) {
                    return table.closest(div_tarto);
                }

            });
        },

    };

    $.fn[pluginName] = function ( options ) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName,
                new Plugin( this, options ));
            }
        });
    };

})(jQuery, window, document);
//-----------------------------------------------------------------------------------------------------------------------------------------
$(function() {
	overlayOn = function()
	{
		$( '<div id="imagelightbox-overlay"></div>' ).appendTo( 'body' );
	},
	overlayOff = function()
	{
		$( '#imagelightbox-overlay' ).remove();
	},
	closeButtonOn = function( instance )
	{
		$( '<button type="button" id="imagelightbox-close" title="Close"></button>' ).appendTo( 'body' ).on( 'click touchend', function(){ $( this ).remove(); instance.quitImageLightbox(); return false; });
	},
	closeButtonOff = function()
	{
		$( '#imagelightbox-close' ).remove();
	}
});